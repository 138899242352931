export default {
  dashboard: "Dashboard",
  storage: "Storage",
  billing: "Billing",
  smartMatching: {
    main: "Smart Matching",
    sub: {
      status: "Matching Status",
      plwida: "PlWiDa Matching",
      highFive: "High Five",
    },
  },
  management: "Management",
  consulting: "Consulting",
  calendar: "Calendar",
  monitoring: "Monitoring",
  dataLabeling: "Data Labeling",
  ai: "AI",
} as const;
