import modelDesign from "./model-design";
import planning from "./planning";

export default {
  planning,
  modelDesign,
  guide: {
    title: "안녕하세요, ATAD Corp. 회원님! 만나 뵙게 되어 반갑습니다.",
    description: {
      first: "저는 Kura라고 합니다.",
      second:
        "엑셀로 작성하신 AI 모델 기획서를 세부적으로 도와드릴 준비가 되어 있습니다.",
      third:
        "제한 없이 최선을 다해 뛰어난 기획서를 작성하실 수 있도록 도와드릴게요.",
      fourth:
        "선택하신 데이터 파일을 활용하여 기획서를 작성하거나 모델을 설계하실 수 있습니다.",
      fifth: "원하시는 다음 단계를 선택해 주세요.",
      sixth: "이 소개는 처음에 한 번만 나타나며,",
      seventh: "작업이 끝날 때까지 Kura가 항상 함께할 것입니다.",
    },
    confirm: "확인",
  },
  middleButton: {
    planning: "기획서 만들기",
    modelDesign: "모델 설계하기",
  },
  inputComponent: {
    placeholder: "궁금한게 있으면 얼마든지 물어보세요.",
  },
  title: {
    first: "Kura가 업로드하신 데이터 파일의",
    second: "기획서 작성과 모델 설계를 도와줄 거예요.",
  },
} as const;
