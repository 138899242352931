export default {
  noStorage: {
    title: "현재 등록된 버킷이 없습니다.",
    subTitle: "버킷을 연동하여 시작해보세요.",
    button: "버킷 연동하기",
  },
  newFolder: "새 폴더",
  connect: "버킷 연동",
  bucketList: {
    title: "버킷 목록",
    menu: {
      informationEdit: "정보 수정",
      disconnect: "연동 해제",
    },
  },
  bucketInfo: {
    bucketInfo: "버킷 정보",
    status: "상태",
    bucketCreatedDate: "버킷 생성일",
    linkedDate: "연동일",
    linkedBy: "연동자",
    bucketName: "버킷명",
    storageClass: "스토리지 클래스",
    region: "AWS리전",
    arn: "Amazon 리소스 이름(ARN)",
    description: "설명",
  },
  bucketTable: {
    name: "이름",
    size: "크기",
    registeredDate: "등록일",
    type: "종류",
  },
  empty: {
    noFile: "파일이 존재하지 않습니다.",
  },
  connected: "연동됨",
  disconnected: "해제됨",
} as const;
