export default {
  message: {
    wrongName:
      " * The bucket name you entered is incorrect. Please check again and enter the correct name.",
    unlink:
      " * When you disconnect the bucket, the files will be permanently deleted and cannot be recovered.",
  },
  title: "Are you sure you want to disconnect this bucket?",
  placeholder: "Please enter the bucket name again to delete.",
} as const;
