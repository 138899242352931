"use client";

import { useTranslation } from "react-i18next";
import LanguageDefault from "_assets/icons/ic_language_default_gray900_20.svg";
import SwitchIcon from "_assets/icons/ic_switch_default_bgray400_20.svg";
import Typography from "_components/Typography";
import { useLanguage } from "_hooks/use-language";
import styled from "styled-components";

export default function LanguageSwitcher() {
  const { t } = useTranslation();

  const { currentLanguage, changeLanguage } = useLanguage();

  return (
    <div
      style={{
        display: "flex",
        gap: "12px",
        justifyContent: "space-between",
        padding: "6px",
      }}
    >
      <div style={{ padding: "0 0  " }}>
        <LanguageDefault />
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <S.MenuItem onClick={() => changeLanguage("ko")}>
          <Typography
            variant="caption.100"
            color={currentLanguage === "ko" ? "primary_main" : "text_5"}
          >
            {t("header.kor")}
          </Typography>
        </S.MenuItem>
        <SwitchIcon />
        <S.MenuItem onClick={() => changeLanguage("en")}>
          <Typography
            variant="caption.100"
            color={currentLanguage === "en" ? "primary_main" : "text_5"}
          >
            {t("header.eng")}
          </Typography>
        </S.MenuItem>
      </div>
    </div>
  );
}

const S = {
  MenuItem: styled.div`
    cursor: pointer;
  `,
};
