export default {
  dashboard: "대시보드",
  storage: "스토리지",
  billing: "결제",
  smartMatching: {
    main: "스마트 매칭",
    sub: {
      status: "매칭 현황",
      plwida: "PlWiDa 매칭",
      highFive: "하이파이브",
    },
  },
  management: "조직 관리",
  consulting: "컨설팅",
  calendar: "캘린더",
  monitoring: "모니터링",
  dataLabeling: "데이터 라벨링",
  ai: "AI",
} as const;
