export default {
  title: "하이파이브",
  tabList: {
    company: "기업",
    fund: "펀드",
    task: "과제",
  },
  highFiveList: {
    searchPlaceholder: "검색어를 입력해주세요.",
    addFunction: "기능 추가",
    companyInfo: "기업 정보",
  },
} as const;
