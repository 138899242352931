export default {
  title: "Matching Status",
  receivedProposal: "Received Proposal",
  limitStatus: "Proposal Status",
  bookmark: "Bookmark",
  matchList: {
    searchPlaceholder: "Enter the company name",
    type: "Type",
    companyName: "Company Name",
    status: "Status",
    proposalDate: "Proposal Date",
    viewProposal: "View Proposal",
    addFunction: "Add Function",
    company: "Company",
    fund: "Fund",
    task: "Task",
    data: "Data",
  },
  bookmarkCard: {
    company: "Company",
    fund: "Fund",
    task: "Task",
    data: "Data",
    propose: "Propose",
  },
} as const;
