import { ChangeEvent, useState } from "react";
import { Controller } from "react-hook-form";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";

import { IFormProps } from ".";

const Name = (props: IFormProps) => {
  const { control } = props;

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onChange = (e: ChangeEvent<HTMLInputElement>, field: any) => {
    const value = e.target.value;

    field.onChange(value);
    if (field.value) return;
  };

  return (
    <Controller
      name="name"
      control={control}
      render={({ field }) => {
        return (
          <Input
            label={
              <div style={{ display: "flex", gap: "4px" }}>
                <Typography variant="caption.100" color="text_20">
                  이름
                </Typography>
                <Typography variant="caption.100" color="system_error">
                  *
                </Typography>
              </div>
            }
            placeholder="이름을 입력해 주세요."
            value={field.value}
            onChange={(e) => onChange(e, field)}
            // error={error}
            // errorMessage={errorMessage}
          />
        );
      }}
    />
  );
};

export default Name;
