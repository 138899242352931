import { Button } from "_components/Button";
import Typography from "_components/Typography";
import useLoginSignUp from "_hooks/use-login-sign-up";
import Lottie from "lottie-react";
import styled from "styled-components";

import clap_lottie from "(route)/my-storage/lottiee_clap.json";

const Complete = () => {
  const { setTab } = useLoginSignUp();

  return (
    <S.Container>
      <S.LottieWrapper>
        <Lottie
          animationData={clap_lottie}
          width={100}
          height={100}
          loop={true}
          autoplay={true}
        />
      </S.LottieWrapper>
      <S.GuideWrapper>
        <Typography variant="title.200_sb" color="text_5">
          회원가입 완료
        </Typography>
        {/* <Typography
          variant="body.200"
          color="text_10"
          style={{ textAlign: "center" }}
        >
          관리자 승인 후에 다양한 서비스를 이용하실 수 있습니다.
          <br />
          (3영업일 이내로 승인됩니다.)
        </Typography> */}
      </S.GuideWrapper>
      <Button
        variant="default"
        size="md"
        onClick={() => setTab("login")}
        width="168px"
      >
        로그인
      </Button>
    </S.Container>
  );
};

export default Complete;

const S = {
  Container: styled("div")<any>(() => ({
    padding: "32px",
    width: "536px",
    background: "white",
    borderRadius: "12px",
    overflow: "hidden",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "28px",
    alignItems: "center",
  })),
  LottieWrapper: styled("div")<any>(() => ({
    display: "flex",
    justifyContent: "center",
  })),
  GuideWrapper: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    alignItems: "center",
  })),
};
