import React, { KeyboardEvent, useEffect, useRef } from "react";
import { set, useForm } from "react-hook-form";
import Slider from "react-slick";
import LOGO from "_assets/logo/logo.svg";
import { Button } from "_components/Button";
import SVG from "_components/SVG/Svg";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { findOneWithPassword, sendEmailToUser } from "_libs/fetcher/auth";
import { findOneByEmail } from "_libs/fetcher/user";
import { getMyUser } from "_libs/swr/user/user";
import styled from "styled-components";

import Login from "./Login";
import { loginStepObj, LoginStepObject } from "./loginStepConfig";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const initData = {
  email: "",
  password: "",
};

const LoginStep = () => {
  const { mutate } = getMyUser();

  const { open } = useSnackBar();

  const { currentStep, handleNextStep, handlePreviousStep, setTab } =
    useLoginSignUp();

  const { handleSubmit, setValue, watch } = useForm({
    defaultValues: initData,
  });

  const email = watch("email");

  const handleLogin = async (props: any) => {
    try {
      const pwres = await findOneWithPassword(props);
      const idres = await findOneByEmail(email);

      if (!idres) {
        open({
          variant: "error",
          text: "해당 이메일로 가입된 계정을 찾을 수 없습니다.",
        });
        return;
      }

      if (!pwres) {
        open({
          variant: "error",
          text: "비밀번호가 일치하지 않습니다.",
        });
        return;
      }

      mutate();
      open({
        variant: "success",
        text: "로그인에 성공하였습니다.",
      });
    } catch (e) {
      console.log(e);
    }
  };

  // const emailCheck = async () => {
  //   const email = watch("email");
  //   const result = await findOneByEmail(email);

  //   if (result) {
  //     return handleNextStep();
  //   } else {
  //     open({
  //       variant: "error",
  //       text: "해당 이메일로 가입된 계정을 찾을 수 없습니다.",
  //     });
  //     return;
  //   }
  // };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && currentStep === 0) {
      // emailCheck();
    }
  };

  const sendEmail = async () => {
    const email = watch("email");
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(email)) {
      open({ text: "이메일 형식이 올바르지 않습니다.", variant: "error" });
      return;
    }

    const result = await sendEmailToUser({ email: email, type: "reset" });

    if (result && result?.isSent === true)
      open({ text: "인증 메일 발송이 완료 되었습니다.", variant: "success" });
    else
      return open({ text: "인증 메일 발송에 실패했습니다.", variant: "error" });

    console.log(result);
  };

  const sliderRef = useRef<Slider>(null);

  useEffect(() => {
    if (currentStep === 0) {
      setValue("email", "");
      setValue("password", "");
    }
    sliderRef.current?.slickGoTo(currentStep);
  }, [currentStep]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    fade: false,
  };

  return (
    <S.Container>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <S.LoginContainer onKeyDown={handleKeyPress}>
          <Slider ref={sliderRef} {...settings}>
            {loginStepObj.map((item: any, index) => (
              <div key={index}>
                {React.cloneElement(item.page, {
                  watch,
                  setValue,
                  handlePreviousStep,
                  handleSubmit,
                  handleNextStep,
                  setTab,
                })}
              </div>
            ))}
          </Slider>
        </S.LoginContainer>
      </div>
    </S.Container>
  );
};

const S = {
  Container: styled("div")<any>(() => ({
    width: "100%",
    height: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    overflowY: "auto",
    margin: "auto",
  })),
  LoginContainer: styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  }),
};

export default LoginStep;
