export default {
  progressSteps: {
    first: "Set a Goal",
    second: "Select a Learning Model",
    third: "Get Recommended Evaluation Metrics",
    fourth: "Analyze Evaluation Metrics",
    fifth: "AI Model Design Complete!",
    title: "Model Design Progress",
    buttonLabel: "Proceed to Train the AI Model",
  },
  kuraChat: {
    buttonLabel: "Recommend Another Example",
    title: "Model Design Step 2",
    description: {
      first: `I’ll automatically recommend a model that aligns with the goal 
              of “Improving the recommendation algorithm based on user behavior 
              data to increase the click-through rate (CTR) from the current 5% 
              to 10%.”`,
      second: "Selected Model: Logistic Regression",
      third: `Suitable for binary classification: This model is ideal for binary 
              classification tasks, such as predicting whether a visitor’s 
              session duration exceeds 100 seconds. 
              Easy to interpret: Logistic regression provides insights into how 
              each feature (or variable) impacts the outcome, which is useful for 
              deriving business insights. 
              Suitable for small datasets: Since the dataset is small and simple, 
              logistic regression is more appropriate than more complex models.`,
    },
  },
  chatInput: {
    placeholder: "Feel free to ask any questions.",
  },
  leftLabel: "Design a Model",
  table: {
    visitDate: "Visit Date",
    visitorId: "Visitor ID",
    pageUrl: "Page URL",
    inboundPath: "Inbound Path",
    stayTime: "Stay Time",
    device: "Device",
    browser: "Browser",
  },
} as const;
