import React, { useState } from "react";
import LEFT_ARROW from "_assets/icons/ic_chevron_left_default_bgray400.svg";
import { Button } from "_components/Button";
import SVG from "_components/SVG/Svg";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { useModal } from "_hooks/use-modal";
import styled from "styled-components";

import { TermsCheckboxes } from "./TermsCheckboxes";
import { terms } from "./termsData";

const Agree = (props: any) => {
  const { setTab } = useLoginSignUp();
  const { sliderRef, control } = props;

  const [agreed, setAgreed] = useState(new Array(terms.length).fill(false));
  const { open, close } = useModal();

  const onButtonClick = (event: any) => {
    event.preventDefault();

    setTab("sign");
  };

  const openTermModal = (index) => {
    open({
      type: "termsAgreement",
      currentTerm: terms[index],
      handleAgree: () => {
        setAgreed((prev) => {
          const newAgreed = [...prev];
          newAgreed[index] = true;
          return newAgreed;
        });
        close();
      },
      isLastTerm: true,
    });
  };

  const handleToggleAgree = (index) => {
    if (agreed[index]) {
      setAgreed((prev) => {
        const newAgreed = [...prev];
        newAgreed[index] = false;
        return newAgreed;
      });
    } else {
      openTermModal(index);
    }
  };

  const handleAllAgree = () => {
    const allAgreed = agreed.every(Boolean);
    if (allAgreed) {
      setAgreed(new Array(terms.length).fill(false));
    } else {
      const uncheckedTerms = terms
        .map((term, index) => ({ ...term, index }))
        .filter((term) => !agreed[term.index]);

      if (uncheckedTerms.length > 0) {
        let currentIndex = 0;
        const openNextModal = () => {
          if (currentIndex < uncheckedTerms.length) {
            open({
              type: "termsAgreement",
              currentTerm: terms[uncheckedTerms[currentIndex].index],
              handleAgree: () => {
                setAgreed((prev) => {
                  const newAgreed = [...prev];
                  newAgreed[uncheckedTerms[currentIndex - 1]?.index] = true;
                  return newAgreed;
                });
                close();
                currentIndex++;
                openNextModal();
              },
              isLastTerm: currentIndex === uncheckedTerms.length - 1,
            });
          }
        };
        openNextModal();
      } else {
        setAgreed(new Array(terms.length).fill(true));
      }
    }
  };

  return (
    <S.Container>
      <TermsCheckboxes
        agreed={agreed}
        handleAllAgree={handleAllAgree}
        handleToggleAgree={handleToggleAgree}
      />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="label"
          size="md"
          onClick={() => setTab("login")}
          startIcon={
            <SVG
              src={LEFT_ARROW}
              width={16}
              height={16}
              stroke="var(--Bgray_500)"
              strokeWidth={1.5}
            />
          }
        >
          이전
        </Button>
        <Button
          variant="default"
          size="md"
          onClick={onButtonClick}
          disabled={!agreed.every(Boolean)}
        >
          다음
        </Button>
      </div>
    </S.Container>
  );
};

export default Agree;

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 513px;
    background: white;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 12px;
  `,
  Title: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 20px;
  `,
  ButtonContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  `,
};
