import { ChangeEvent, useState } from "react";
import { Controller } from "react-hook-form";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";

import { IFormProps } from ".";

const CompanyNumber = (props: IFormProps) => {
  const { control } = props;

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onChange = (e: ChangeEvent<HTMLInputElement>, field: any) => {
    let value = e.target.value;

    value = value.replace(/[^0-9]/g, "");

    if (value.length > 5) {
      value =
        value.slice(0, 3) + "-" + value.slice(3, 5) + "-" + value.slice(5);
    } else if (value.length > 3) {
      value = value.slice(0, 3) + "-" + value.slice(3);
    }

    value = value.slice(0, 12);

    field.onChange(value);

    if (field.value) return;
  };

  return (
    <Controller
      name="companyNumber"
      control={control}
      render={({ field }) => {
        return (
          <Input
            label={
              <Typography variant="caption.100" color="text_20">
                사업자 등록번호
              </Typography>
            }
            placeholder="사업자 등록번호 10자리를 입력해 주세요."
            value={field.value}
            onChange={(e) => onChange(e, field)}
            // error={error}
            // errorMessage={errorMessage}
          />
        );
      }}
    />
  );
};

export default CompanyNumber;
