import { apiInstance } from "_utils/axiosInstance";

export async function sendEmailToUser(authenticateUserReqDto: {
  email: string;
  type: "setting" | "reset";
}) {
  try {
    const { data } = await apiInstance.post(
      "auth/email",
      authenticateUserReqDto,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function findOneWithPassword(loginUserReqDto: any) {
  try {
    const { data } = await apiInstance.post("auth/login", loginUserReqDto);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function verifyResetPasswordToken(token: string) {
  try {
    const { data } = await apiInstance.get(
      `auth/verify-reset-token?token=${token}`,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function logout() {
  try {
    const { data } = await apiInstance.post("/auth/logout");
    return data;
  } catch (error) {
    return error;
  }
}

/**
 * @remark 로그인
 */

export async function Login(loginDto: any, endUserId: string) {
  try {
    const { data } = await apiInstance.post(
      `auths/auth/login/${endUserId}`,
      loginDto,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

/**
 * @remark 로그아웃
 */

export async function Logout() {
  try {
    const { data } = await apiInstance.post("auths/auth/logout");
    return data;
  } catch (error) {
    return console.log(error);
  }
}

/**
 * @remark 이메일 인증
 */

export async function authenticateEmail(email: string, endUserId: string) {
  try {
    const { data } = await apiInstance.post(`auths/auth/${endUserId}`, {
      params: { email },
    });
    return data;
  } catch (error) {
    return console.log(error);
  }
}

/**
 * @remark 회원 가입
 */

export async function authUserCreate(createUserDto: any) {
  try {
    const { data } = await apiInstance.post("auths/user", createUserDto);
    return data;
  } catch (error) {
    return console.log(error);
  }
}
