import { useEffect, useRef, useState } from "react";
import LOGO from "_assets/logo/logo.svg";
import { Button } from "_components/Button";
import { Checkbox } from "_components/Checkbox";
import Input from "_components/Input/Input";
import SVG from "_components/SVG/Svg";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import { findOneWithPassword } from "_libs/fetcher/auth";
import { findOneByEmail } from "_libs/fetcher/user";
import { getMyUser } from "_libs/swr/user/user";
import { icons } from "_styles/shared/icons";
import Cookies from "js-cookie";
import Image from "next/image";
import styled from "styled-components";

const Login = (props: any) => {
  const { open } = useSnackBar();
  const { watch, setValue, handleSubmit, setTab } = props;

  const email = watch("email");

  const { mutate } = getMyUser();

  const emailRef = useRef(null);
  const pwRef = useRef(null);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [saveId, setSaveId] = useState(false);
  const [initialEmail, setInitialEmail] = useState("");

  const savedId = Cookies.get("plwida_id_save");

  useEffect(() => {
    if (savedId) {
      setValue("email", savedId);
      setInitialEmail(savedId);
      setSaveId(true);
    }
  }, []);

  const password = watch("password");

  const handleOnEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("email", e.target.value);
  };

  const handleOnPwChange = (e) => {
    setValue("password", e.target.value);
  };

  const handleLogin = async (props: any) => {
    const loginUserReqDto = {
      email: email || initialEmail,
      password: props.password,
    };

    try {
      const pwres = await findOneWithPassword(loginUserReqDto);
      const idres = await findOneByEmail(email || initialEmail);

      if (!idres) {
        open({
          variant: "error",
          text: "해당 이메일로 가입된 계정을 찾을 수 없습니다.",
        });
        return;
      }

      if (!pwres) {
        open({
          variant: "error",
          text: "비밀번호가 일치하지 않습니다.",
        });
        return;
      }

      // 아이디 저장 체크 시 쿠키 저장
      if (saveId) {
        Cookies.set("plwida_id_save", email);
      }

      // 아이디 저장 체크 해제 시 쿠키 삭제
      if (!saveId) {
        Cookies.remove("plwida_id_save");
      }

      mutate();
      open({
        variant: "success",
        text: "로그인에 성공하였습니다.",
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <S.Container>
      <form
        onSubmit={handleSubmit(handleLogin)}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <S.LoginContainer>
          <S.Title>
            <SVG src={LOGO} fill="fill" />
            <Typography variant="title.200_sb" color="text_5">
              기업 회원 로그인
            </Typography>
          </S.Title>
          <div>
            {/* 인풋 영역 */}
            <S.InputContainer>
              <Input
                ref={emailRef}
                value={email || initialEmail}
                autoFocus={true}
                onChange={handleOnEmailChange}
                placeholder={"이메일 주소를 입력해주세요."}
              />
              <Input
                ref={pwRef}
                type={isPasswordVisible ? "text" : "password"}
                value={password}
                placeholder={"비밀번호를 입력해주세요."}
                onChange={handleOnPwChange}
                endAdornment={
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsPasswordVisible(!isPasswordVisible);
                    }}
                  >
                    {isPasswordVisible ? (
                      <Image
                        src={icons.eye.on}
                        alt="eye"
                        width={20}
                        height={20}
                      />
                    ) : (
                      <Image
                        src={icons.eye.off}
                        alt="eye"
                        width={20}
                        height={20}
                      />
                    )}
                  </div>
                }
              />
            </S.InputContainer>

            <S.CheckboxContainer>
              {/* <Checkbox
                id=""
                label="자동 로그인"
                defaultChecked
                checked={autoLogin}
                onChange={(e) => setAutoLogin(e.target.checked)}
              /> */}

              <Checkbox
                id=""
                label="아이디 저장"
                defaultChecked
                checked={saveId}
                onChange={(e) => setSaveId(e.target.checked)}
              />
            </S.CheckboxContainer>

            {/* 로그인 버튼 영역 */}
            <S.SubContainer>
              <Button variant="default" size="md" width="100%">
                <Typography variant="body.200" color="text_60">
                  로그인
                </Typography>
              </Button>

              <S.ActionLinksContainer>
                {/* <div
                  onClick={() => setTab("email")}
                  style={{ cursor: "pointer" }}
                >
                  <Typography variant="caption.100" color="text_10">
                    이메일 찾기
                  </Typography>
                </div>

                <S.Divider />

                <div
                  onClick={() => setTab("password")}
                  style={{ cursor: "pointer" }}
                >
                  <Typography variant="caption.100" color="text_10">
                    비밀번호 재설정
                  </Typography>
                </div> */}
              </S.ActionLinksContainer>
            </S.SubContainer>
          </div>

          <div>
            {/* 회원가입 버튼 영역 */}
            <S.SignupButtonContainer>
              <S.BorderLine />

              <Button
                variant="line"
                size="md"
                width="100%"
                style={{ marginBottom: "200px" }}
                type="button"
                onClick={() => setTab("agree")}
              >
                회원 가입
              </Button>
            </S.SignupButtonContainer>

            {/* 하단 텍스트 영역 */}
            <S.BottomTextContainer>
              <Typography
                variant="body.300"
                color="text_10"
                style={{ display: "flex" }}
              >
                복잡한 절차 없이
                <Typography
                  style={{ marginLeft: "4px" }}
                  variant="body.300_sb"
                  color="text_10"
                >
                  하나의 이메일
                </Typography>
                로 KURV X PLWIDa 모두 OK!
              </Typography>
            </S.BottomTextContainer>
          </div>
        </S.LoginContainer>
      </form>
    </S.Container>
  );
};
export default Login;

const S = {
  Container: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    padding: "40px",
    boxSizing: "border-box",
    borderRadius: "12px",
    margin: "72px 0",
    width: "572px",
  })),
  ButtonContainer: styled("div")<any>(() => ({
    marginTop: "16px",
    marginBottom: "12px",
  })),
  LoginContainer: styled("div")({
    width: "342px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  }),
  SubContainer: styled("div")({
    display: "flex",
    gap: "12px",
    flexDirection: "column",
  }),
  CheckboxContainer: styled("div")({
    display: "flex",
    gap: "20px",
    margin: "20px 0 28px 0",
    alignItems: "center",
  }),
  Divider: styled("div")({
    width: "1px",
    backgroundColor: "var(--line_20)",
    height: "16px",
  }),
  ActionLinksContainer: styled("div")({
    display: "flex",
    gap: "12px",
    alignItems: "center",
  }),
  SignupButtonContainer: styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
  BottomTextContainer: styled("div")({
    display: "flex",
    justifyContent: "center",
  }),
  BorderLine: styled("div")({
    border: "1px solid var(--background_default2)",
  }),
  InputContainer: styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  }),
  Title: styled("div")<any>(() => ({
    marginBottom: "28px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "56px",
  })),
};
