import consulting from "./consulting";
import create from "./create";
import fileSelect from "./fileSelect";
import infoEdit from "./infoEdit";
import unlink from "./unlink";

export default {
  confirm: "확인",
  cancel: "취소",
  create,
  infoEdit,
  unlink,
  fileSelect,
  consulting,
} as const;
