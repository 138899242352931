import modelDesign from "./model-design";
import planning from "./planning";

export default {
  planning,
  modelDesign,
  guide: {
    title: "Hello, ATAD Corp. member! It’s great to meet you.",
    description: {
      first: "My name is Kura.",
      second:
        "I’m here to assist you with detailing your AI model plan written in Excel.",
      third:
        "I’ll do my best to help you create an exceptional plan without limitations.",
      fourth:
        "You can use your selected data file to draft the plan or design the model.",
      fifth: "Please choose your next step to proceed.",
      sixth: "This introduction will only appear once,",
      seventh:
        "but Kura will be here to support you until the task is completed.",
    },
    confirm: "Confirm",
  },
  middleButton: {
    planning: "Create Plan",
    modelDesign: "Design Model",
  },
  inputComponent: {
    placeholder: "Ask me anything.",
  },
  title: {
    first: "Kura uploaded the data file of",
    second: "I'll help you write a plan or design a model.",
  },
} as const;
