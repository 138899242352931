export default {
  bucketSelect: {
    title: "버킷 선택",
    description: "버킷 목록 중 스토리지에 연동할 버킷을 선택하세요.",
    button: "버킷 조회",
    next: "다음",
  },
  corsSetting: {
    title: "CORS 설정",
    bucket: "버킷",
    description: "해당 스토리지의 연동하려는 버킷을 입력합니다.",
    copy: "복사",
    confirm: "확인",
  },
  bucketInfo: {
    title: "버킷 정보",
    name: "이름",
    namePlaceholder: "플리다에 표시될 이름을 입력해주세요.",
    description: "설명(optional)",
    descriptionPlaceholder: "설명을 입력해주세요.",
  },
  connect: "연동하기",
  loading: "버킷 목록을 불러오는 중입니다.",
  noBucket: "연동 가능한 버킷이 없습니다.",
} as const;
