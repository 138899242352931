import highFive from "./high-five";
import matchingStatus from "./matching-status";
import plwidaMatching from "./plwida-matching";

export default {
  matchingStatus,
  plwidaMatching,
  highFive,
  userMatchStatus: {
    receivedProposal: "Received Proposal",
    limitStatus: "Proposal Status",
    bookmark: "Bookmark",
    viewCount: "View Count",
    myMatchingInfo: "My Matching Info",
    myMatchingInfoDescription: " Matching Status",
  },
  plwidaMatch: {
    title: "PlWiDa Matching",
    viewAll: "View All",
    menu: {
      quickFindCompany: {
        title: "Quick Find Company",
        sub: "🖐🏻 High Five! / Match with Partner Company",
      },
      increaseCompanyValue: {
        title: "Increase Company Value",
        sub: "✨ My Desired / Fund Matching",
      },
      searchDifficultTask: {
        title: "Search Difficult Task",
        sub: "👀 I'm Looking For / Recommend Task",
      },
      customRecommendService: {
        title: "Custom Recommend Service",
        sub: "🗃️ My Personal / Dataset Product",
      },
    },
  },
  news: {
    title: "Company Trend NEWS",
  },
  highFiveCompany: {
    title: "High Five Company",
    viewAll: "View All",
    companyInfo: "Company Info",
  },
} as const;
