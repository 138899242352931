export default {
  dataLabeling: {
    title: "데이터를 라벨링할 파일을 선택해 주세요.",
  },
  ai: {
    title: "학습용 데이터를 선택해 주세요.",
  },
  message: {
    uploadSuccess: "파일을 성공적으로 업로드했습니다!",
  },
  upload: "업로드",
} as const;
