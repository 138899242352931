import labeling from "./labeling";

export default {
  labeling,
  kuraAssistCard: {
    title: "Kura Assist",
    description: {
      first: "Difficult and complex labeling",
      second: "Kura will help you from A to Z.",
    },
    button: "Create my model",
  },
  aiModelStats: {
    title: "AI Training Model",
    content: {
      newPartner: "New Partner",
      kuraAssist: "Kura Assist",
      kuraAssist2: "Get to know Kura",
      kuraAssist3: "Used in PIWIDA",
      usage: "100% Usage",
      dataLabeling: "Data Labeling",
      process: "Process",
    },
  },
  aiModelUsageSamples: {
    title: "Data Labeling Examples",
    description: "You can learn by looking at the data labeling examples.",
  },
  taskList: {
    title: "My Data Labeling Status",
    description: "Recent data labeling.",
    table: {
      name: "Name",
      recentWorkDate: "Recent Work Date",
      status: "Status",
    },
  },
} as const;
