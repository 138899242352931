import labeling from "./labeling";

export default {
  labeling,
  kuraAssistCard: {
    title: "Kura Assist",
    description: {
      first: "어렵고 복잡하게만 느껴졌던 라벨링",
      second: "Kura가 a-z까지 도와드릴게요.",
    },
    button: "내 모델 만들기",
  },
  aiModelStats: {
    title: "AI 학습 모델 인공지능",
    content: {
      newPartner: "새로운 파트너",
      kuraAssist: "Kura Assist",
      kuraAssist2: "Kura와 친해지기",
      kuraAssist3: "PIWIDa에서 사용하는",
      usage: "100% 활용법",
      dataLabeling: "데이터 라벨링",
      process: "프로세스",
    },
  },
  aiModelUsageSamples: {
    title: "데이터 라벨링 예제",
    description: "데이터 라벨링 예제를 보고 학습해 볼 수 있어요.",
  },
  taskList: {
    title: "내 데이터 라벨링 현황",
    description: "최근에 작업 한 데이터 라벨링 입니다.",
    table: {
      name: "이름",
      recentWorkDate: "최근 작업일",
      status: "상태",
    },
  },
} as const;
