import { ChangeEvent, useState } from "react";
import { Controller } from "react-hook-form";
import { Button } from "_components/Button";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import styled from "styled-components";

const VerifyStepOne = (props: any) => {
  const { sliderRef, control, setTab } = props;

  const [email, setEmail] = useState("");

  const onButtonClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>, field: any) => {
    const value = e.target.value;

    field.onChange(value);

    setEmail(value);
  };

  const isButtonDisabled = email.trim() === "" || !email;

  return (
    <S.Container>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              placeholder="이메일을 입력해 주세요."
              label={
                <Typography variant="caption.100" color="text_20">
                  이메일
                </Typography>
              }
              value={field.value}
              onChange={(e) => onChange(e, field)}
            />
          )}
        />

        <Typography variant="body.300" color="text_5">
          본인 인증 후에 임시 비밀번호를 받을 수 있습니다.
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          gap: "12px",
          alignSelf: "flex-end",
          marginTop: "48px",
        }}
      >
        <Button
          variant="gray2"
          size="md"
          type="button"
          onClick={() => setTab("login")}
          style={{ width: "80px" }}
        >
          이전
        </Button>

        <Button
          variant="default"
          size="md"
          type="button"
          onClick={onButtonClick}
          disabled={isButtonDisabled}
          style={{ width: "80px" }}
        >
          다음
        </Button>
      </div>
    </S.Container>
  );
};

export default VerifyStepOne;

const S = {
  Container: styled("div")<any>(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    gap: "48px",
  })),
};
