export default {
  organization: {
    title: "Organization",
    add: "Add Organization",
    all: "All",
    searchPlaceholder: "Enter a search term.",
    organizationName: "Organization Name",
    organizationLeader: "Leader",
    members: "Members",
  },
  members: {
    title: "Members",
    name: "Name",
    email: "Email",
    jobTitle: "Job Title",
    authority: "Authority",
    modify: "Edit Member",
  },
  modifyMemberModal: {
    title: "Member Information",
    name: "Name",
    email: "Email",
    organization: "Organization",
    jobTitle: "Job Title",
    authority: "Authority",
    changeLeader: "Change Leader",
    save: "Save",
    cancel: "Cancel",
    message: {
      success: "Member information has been updated.",
    },
  },
} as const;
