import { KeyboardEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PLWIDA from "_assets/logo/plwida.svg";
import { Button } from "_components/Button";
import SVG from "_components/SVG/Svg";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { sendEmailToUser } from "_libs/fetcher/auth";
import { findOneByEmail } from "_libs/fetcher/user";
import styled from "styled-components";

import Form from "./Form";

const CompanyForm = () => {
  const { setTab } = useLoginSignUp();

  const initData = {
    name: "", // 필수 값
    owner: "",
    companyNumber: "",
    companyAddress: "",
    companyDetailAddress: "",
    email: "", // 필수 값
    password: "", // 필수 값
    confirmPassword: "",
    recoveryEmail: "", // 필수 값
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues: getvalues,
    setValue: setvalue,
  } = useForm({
    defaultValues: initData,
  });
  const [isFormFilled, setIsFormFilled] = useState(false);

  const { name, email, password, recoveryEmail } = watch();

  useEffect(() => {
    setIsFormFilled(
      Boolean(name?.trim()) &&
        Boolean(email?.trim()) &&
        Boolean(password?.trim()) &&
        Boolean(recoveryEmail?.trim()),
    );
  }, [name, email, password, recoveryEmail]);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { open } = useSnackBar();

  // const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setEmail(e.target.value);
  // };

  const SignEmail = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(email)) {
      setError(true);
      setErrorMessage("이메일 형식이 올바르지 않습니다.");
      return;
    }

    const result = await findOneByEmail(email);

    console.log(result);

    if (result === true) {
      setError(true);
      setErrorMessage("이미 존재하는 계정입니다.");
      return;
    }

    setTab("complete");
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      SignEmail();
    }
  };

  const SendEmail = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(email)) {
      setError(true);
      setErrorMessage("이메일 형식이 올바르지 않습니다.");
      return;
    }

    const result = await sendEmailToUser({ email: email, type: "setting" });

    if (result && result?.isSent === true) setTab("complete");
    else
      return open({ text: "인증 메일 발송에 실패했습니다.", variant: "error" });

    console.log(result);
  };

  // const SendEmail = async () => {
  //   const result = await sendEmailToUser({ email: email, type: "setting" });

  //   if (result.isSent === true) {
  //     open({
  //       text: "인증 메일이 재발송되었습니다.",
  //       variant: "success",
  //     });
  //   }

  //   console.log(result);
  // };

  return (
    <S.Container>
      <S.FormWrapper>
        <S.FormBox onKeyDown={handleKeyPress}>
          <S.Title>
            <SVG src={PLWIDA} fill="fill" width={102} height={16} />
            <Typography variant="title.200" color="text_5">
              기업 회원가입
            </Typography>
          </S.Title>

          <Form
            control={control as any}
            handleSubmit={handleSubmit}
            reset={reset}
            initData={initData}
          >
            <Form.Name />
            <Form.Owner />
            <Form.Number />
            <Form.Adress />
            <Form.Email />
            <Form.Password />
            <Form.RecoveryEmail />
          </Form>

          <Button
            variant="default"
            size="lg"
            onClick={() => setTab("complete")}
            style={{ width: "100%", marginTop: "80px" }}
            disabled={!isFormFilled}
          >
            회원 가입하기
          </Button>
        </S.FormBox>
      </S.FormWrapper>
    </S.Container>
  );
};

export default CompanyForm;

const S = {
  Container: styled("div")({
    width: "100%",
    height: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    overflowY: "auto",
  }),
  Title: styled("div")({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    justifyContent: "center",
    marginBottom: "20px",
  }),
  FormBox: styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    padding: "40px",
    boxSizing: "border-box",
    borderRadius: "12px",
    margin: "72px 0",
  }),
  FormWrapper: styled("div")({
    height: "auto",
    width: "531px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    margin: "auto",
  }),
};
