import EMAIL from "_assets/icons/il_sendmail_84.svg";
import { Button } from "_components/Button";
import SVG from "_components/SVG/Svg";
import Typography from "_components/Typography";

const SendEmail = (props: any) => {
  const { sliderRef, watch } = props;

  const email = watch("email");

  const onButtonClick = (event: any) => {
    // 이메일 인증 요청 api 추가 예정

    event.preventDefault();
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <Typography variant="body.100" color="text_5">
        {email}(으)로 인증 메일을 전송합니다.
      </Typography>
      <SVG src={EMAIL} width={84} height={84} fill="fill" />
      <div style={{ alignSelf: "flex-end", marginTop: "22px" }}>
        <Button variant="grayline" size="md" onClick={onButtonClick}>
          보내기
        </Button>
      </div>
    </div>
  );
};

export default SendEmail;
