import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LogoutIcon from "_assets/icons/ic_logout_default_gray900_20.svg";
import { Button } from "_components/Button";
import { Dropdown } from "_components/Dropdown";
import SearchInput from "_components/Input/SearchInput";
import LanguageSwitcher from "_components/LanguageSwitcher";
import Typography from "_components/Typography";
import { logout } from "_libs/fetcher/auth";
import colorToken from "_styles/colorToken";
import { icons } from "_styles/shared/icons";
import Image from "next/image";
import styled from "styled-components";

const Header = () => {
  const { control } = useForm();
  const [menuVisible, setMenuVisible] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLogout = async () => {
    await logout();
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!event.target.closest(".dropdown-menu")) {
        setMenuVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <S.Header>
      {/* search */}
      <S.Search>
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <SearchInput
              placeholder={t("header.searchPlaceholder")}
              value={field.value}
              onChange={field.onChange}
              fullWidth
            />
          )}
        />
      </S.Search>

      {/* menu */}
      <S.Menu className="dropdown-menu">
        {/* <LanguageSwitcher /> */}
        <Image alt="" src={icons.bell} width={24} height={24} />
        <Image
          alt=""
          src={icons.profile.profile_44}
          width={44}
          height={44}
          onClick={toggleMenu}
        />
        {menuVisible && (
          <S.DropdownMenu>
            <LanguageSwitcher />

            <S.MenuItem onClick={handleLogout}>
              <LogoutIcon />
              <Typography variant="caption.100" color="text_5">
                {t("header.logout")}
              </Typography>
            </S.MenuItem>
          </S.DropdownMenu>
        )}
      </S.Menu>
    </S.Header>
  );
};

const S = {
  Header: styled("div")(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid var(--line_10)",
    padding: "12px 20px",
    backgroundColor: "var(--white)",
  })),
  Menu: styled("div")(() => ({
    display: "flex",
    gap: "20px",
    alignItems: "center",
    img: {
      cursor: "pointer",
    },
  })),
  Search: styled("div")(() => ({
    width: "100%",
    maxWidth: "322px",
  })),
  DropdownMenu: styled("div")({
    position: "absolute",
    top: "100%",
    right: "20px",
    backgroundColor: "white",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.15)",
    zIndex: 1,
    padding: "8px",
    borderRadius: "12px",
    width: "auto",
  }),
  MenuItem: styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "6px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colorToken.primary_main_5dp,
    },
  })),
};

export default Header;
