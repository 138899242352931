import { cloneElement, Fragment } from "react";
import {
  Control,
  FieldValues,
  useForm,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";

import CompanyAdress from "./Adress";
import Email from "./Email";
import Name from "./Name";
import CompanyNumber from "./Number";
import Owner from "./Owner";
import Password from "./Password";
import RecoveryEmail from "./RecoveryEmail";

export interface IFormProps {
  title?: string;
  control?: Control<FieldValues, any>;
  register?: UseFormRegister<FieldValues>;
  watch?: UseFormWatch<any>;
  getvalues?: any;
  setvalue?: any;
  sampleImage?: string | string[];
}

interface FormProps {
  children: any;
  control?: Control<FieldValues, any>;
  handleSubmit?: any;
  register?: UseFormRegister<FieldValues>;
  watch?: UseFormWatch<any>;
  getvalues?: any;
  setvalue?: any;
  reset?: any;
  initData?: any;
}

const Form = (props: FormProps) => {
  const {
    children,
    control,
    handleSubmit,
    register,
    watch,
    getvalues,
    setvalue,
    reset,
    initData,
  } = props;
  const childArray = Array.isArray(children) ? children : [children];

  // const { name, owner, companyNumber, companyAddress } = watch();
  // console.log(name, owner);

  const handleSignUp = async (props: any) => {
    // const result = await createUser(props);
    // if (result) {
    //   handleNextStep();
    // } else {
    //   open({
    //     variant: "error",
    //     text: "회원가입에 실패했습니다.",
    //   });
    // }
  };

  return (
    <form
      onSubmit={handleSubmit(handleSignUp)}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: "100%",
      }}
    >
      {childArray.map((child, i) => (
        <Fragment key={i}>
          {cloneElement(child, {
            ...child.props,
            register,
            control,
            watch,
            getvalues,
            setvalue,
            reset,
            initData,
          })}
        </Fragment>
      ))}
    </form>
  );
};

export default Form;

Form.Name = Name;
Form.Email = Email;
Form.Password = Password;
Form.RecoveryEmail = RecoveryEmail;
Form.Number = CompanyNumber;
Form.Adress = CompanyAdress;
Form.Owner = Owner;
