import detail from "./detail";

export default {
  detail,
  title: "AI 모델 생성하기",
  kuraAssistCard: {
    title: "Kura Assist",
    description: {
      first: "Kura가 AI 모델 구축 부터",
      second: "평가 지표 작성 까지 도와드릴게요.",
    },
    button: "내 모델 만들기",
  },
  aiModelStats: {
    title: "AI 학습 모델 인공지능",
    content: {
      newPartner: "새로운 파트너",
      kuraAssist: "Kura Assist",
      getToKnowKura: "Kura와 친해지기",
      usingInPIWIDa: "PIWIDa에서 사용하는",
      usage: "100% 활용법",
      aiModelBuilding: "AI 모델 구축",
      process: "프로세스",
    },
  },
  aiModelUsageSamples: {
    title: "다른 기업 모델 성공 사례",
    description: "다른 기업들의 공유해준 모델 성공 사례를 참고해 보세요.",
  },
  taskList: {
    title: "내 모델 현황",
    description:
      "최근에 작업한 AI 모델입니다. 더블 클릭하면 이어서 작업할 수 있어요.",
    table: {
      name: "이름",
      recentWorkDate: "최근 작업일",
      status: "상태",
      evaluationProgress: "평가진행",
    },
  },
} as const;
