export default {
  title: "데이터로 성공을 이끄는 컨설팅",
  content: "맞춤형 분석과 인사이트로 비지니스 성장을 가속화하세요.",
  checkSchedule: "일정 확인하기",
  startConsulting: "컨설팅 시작하기",
  alreadyConsulting: "이미 존재하는 컨설팅 신청이 있어요.",
  successConsulting: "컨설팅이 신청이 완료되었어요.",
  one: {
    title: "연락받으실 연락처를 확인해주세요",
    content: "해당 연락처로 커피챗 알림을 받을 수 있어요.",
    companyName: {
      label: "기업 및 상호명",
      placeholder: "기업 및 상호명 입력해주세요.",
    },
    chiefName: {
      label: "대표자명",
      placeholder: "대표자명 입력해주세요.",
    },
    phoneNumber: {
      label: "연락처",
      placeholder: "연락처 입력해주세요.",
    },
    email: {
      label: "이메일",
      placeholder: "이메일 입력해주세요.",
      error: "이메일 형식이 올바르지 않습니다.",
    },
  },
  two: {
    title: "컨설팅을 원하시나요?",
    content: "필요한 정보를 입력해 주시면 최적화된 컨설팅으로 도와드릴게요.",
    field: "분야",
    fieldList: {
      project: "과제",
      fund: "펀드",
      data: "데이터",
      cloud: "클라우드",
      etc: "기타",
    },
    request: {
      label: "컨설팅 요청 내용",
      placeholder: "컨설팅 요청 내용 입력해주세요.",
    },
  },
  three: {
    title: "나에게 컨설팅 해줄 담당자를 선택해 주세요",
    content: "선택하신 시간에 맞춰 담당자에게 연락이 와요.",
    consultant: "담당자",
    monday: "월",
    tuesday: "화",
    wednesday: "수",
    thursday: "목",
    friday: "금",
    saturday: "토",
    sunday: "일",
    meetingMethod: "원하시는 미팅 방법을 선택해 주세요.",
    meetingMethodList: {
      zoom: {
        label: "Zoom 화상 미팅",
        description: "컨설팅이 확정되면 이메일로 줌 링크를 보내드려요.",
      },
      offline: {
        label: "오프라인 대면 미팅",
        description:
          "컨설팅이 확정되면 이메일로 오프라인 미팅 장소를 보내드려요.",
      },
    },
  },
  next: "다음",
  submit: "신청하기",
  prev: "이전",
} as const;
