export default {
  monthToDateCost: "이번 달 누적 비용",
  estimatedCost: "예상 비용",
  previousDayCost: "전일 비용",
  previousMonthCost: "전월 비용",
  invoiceDownload: "내역서 다운로드",
  forecastCost: "6개월 이상의 데이터가 필요합니다.",
  monthlyCost: {
    title: "월별 비용",
  },
  dailyCost: {
    title: "일별 비용",
  },
  productCost: {
    title: "제품별 비용",
  },
  regionCost: {
    title: "지역별 비용",
    region: "지역",
    previousMonth: "지난 달",
    thisMonth: "이번 달",
    monthToMonth: "(지난 달-이번 달)",
  },
} as const;
