export default {
  noStorage: {
    title: "There are currently no registered buckets.",
    subTitle: "Start by linking your bucket.",
    button: "Connect bucket",
  },
  newFolder: "New Folder",
  connect: "Connect bucket",
  bucketList: {
    title: "Bucket List",
    menu: {
      informationEdit: "Edit",
      disconnect: "Disconnect",
    },
  },
  bucketInfo: {
    bucketInfo: "Bucket Information",
    status: "Status",
    bucketCreatedDate: "Bucket Created Date",
    linkedDate: "Linked Date",
    linkedBy: "Linked By",
    bucketName: "Bucket Name",
    storageClass: "Storage Class",
    region: "Region",
    arn: "Amazon Resource Name (ARN)",
    description: "Description",
  },
  bucketTable: {
    name: "Name",
    size: "Size",
    registeredDate: "Registered Date",
    type: "Type",
  },
  empty: {
    noFile: "No files exist.",
  },
  connected: "Connected",
  disconnected: "Disconnected",
} as const;
