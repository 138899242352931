export default {
  leftLabel: "AI 기획서 만들기",
  kuraChat: {
    title: "마인드 맵 작성하기",
    description: {
      first: `웹사이트_방문_트래픽_로그 파일을 통해서 해결하려는 비지니스 문제나
              과제를 말해주세요. 구체적이고 측정 가능한 목표면 더더욱 좋아요!
              예를 들어, "제품 추천 시스템의 클릭률을 15% 향상시키기" 와 같은
              목표가 있어요.`,
      second: `목표 설정이 완료 되었어요! 정해진 목표를 위한 마인드맵을
              그려드릴게요.확인을 누르면 제 대답은 사라져요. 궁금한게 있다면
              언제든지 채팅으로 저를 불러주세요!`,
    },
    buttonLabel: "확인",
  },
  searchPlaceholder: "궁금한게 있으면 얼마든지 물어보세요.",
  mindMap: {
    snackbar: "기획서를 만들기 위한 마인드맵 제작 중",
  },
} as const;
