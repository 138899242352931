import React from "react";
import LOGO from "_assets/logo/logo.svg";
import SVG from "_components/SVG/Svg";
import Typography from "_components/Typography";

import SignUp from "../SignUp";

import Login from "./Login";

export type LoginStepObject = {
  title: React.ReactNode;
  page: React.ReactNode;
  prevStep?: number;
  nextStep?: number;
};

export const loginStepObj: LoginStepObject[] = [
  {
    title: (
      <div
        style={{
          marginBottom: "28px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "56px",
        }}
      >
        <SVG src={LOGO} fill="fill" />
        <Typography variant="title.200_sb" color="text_5">
          기업 회원 로그인
        </Typography>
      </div>
    ),
    page: <Login />,
    nextStep: 1,
  },
];
