export default {
  resourceStatus: "Resource Status",
  vm: "VM",
  total: "Total",
  stopped: "Stopped",
  running: "Running",
  other: "Other",
  search: "Enter the server you are looking for.",
  cpuUtilization: "CPU Utilization",
  memUsage: "Memory Usage",
  diskUsage: "Disk Usage",
} as const;
