export default {
  add: "Add",
  today: "Today",
  month: "Month",
  week: "Week",
  day: "Day",
  sunday: "Sun",
  monday: "Mon",
  tuesday: "Tue",
  wednesday: "Wed",
  thursday: "Thu",
  friday: "Fri",
  saturday: "Sat",
  myCalendar: {
    title: "My Calandars",
    businessTrip: "Business Trip",
    meeting: "Meeting",
    personalSchedule: "Personal Schedule",
    consulting: "Consulting",
  },
  project: "Project",
} as const;
