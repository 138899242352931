export default {
  resourceStatus: "리소스 상태",
  vm: "VM",
  total: "전체",
  stopped: "중지",
  running: "실행",
  other: "기타",
  search: "찾는 서버를 입력해 보세요.",
  cpuUtilization: "CPU 사용률",
  memUsage: "메모리 사용량",
  diskUsage: "디스크 사용량",
} as const;
