import Complete from "./Complete";
import EmailVerify from "./EmailVerify";
import SendEmail from "./SendEmail";
import SendEmailComplete from "./SendEmailComplete";

export type EmailStepObject = {
  page: React.ReactNode;
  prevStep?: number;
  nextStep?: number;
};

export const emailStepConfig: EmailStepObject[] = [
  {
    page: <EmailVerify />,
    nextStep: 1,
  },
  {
    page: <SendEmail />,
    nextStep: 2,
    prevStep: 0,
  },
  { page: <SendEmailComplete />, nextStep: 3, prevStep: 1 },
  { page: <Complete />, nextStep: 4, prevStep: 2 },
];
