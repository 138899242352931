import { ChangeEvent, useState } from "react";
import { Controller } from "react-hook-form";
import { Button } from "_components/Button";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import { sendEmailToUser } from "_libs/fetcher/auth";

import { IFormProps } from ".";

const Password = (props: IFormProps) => {
  const { control } = props;

  const { open } = useSnackBar();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [buttonText, setButtonText] = useState("인증하기");

  const onChange = (e: ChangeEvent<HTMLInputElement>, field: any) => {
    const value = e.target.value;

    field.onChange(value);
    if (field.value) return;
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  const startTimer = () => {
    setIsTimerActive(true);
    setSeconds(300);

    const timer = setInterval(() => {
      setSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setButtonText("재전송");
          setIsTimerActive(false);

          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const SendEmail = async (email: string) => {
    if (isTimerActive) return;

    if (!email || email.trim() === "") {
      setError(true);
      setErrorMessage("이메일을 입력해주세요.");
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(email)) {
      setError(true);
      setErrorMessage("이메일 형식이 올바르지 않습니다.");
      return;
    }

    const result = await sendEmailToUser({ email: email, type: "setting" });

    if (result && result?.isSent === true) {
      console.log("성공");
      setError(false);
      setErrorMessage("메일이 전송되었습니다. 이메일을 확인해 주세요.");
      startTimer(); // 이메일 전송 성공시 타이머 시작
    } else {
      return open({ text: "인증 메일 발송에 실패했습니다.", variant: "error" });
    }
  };

  return (
    <Controller
      name="recoveryEmail"
      control={control}
      render={({ field }) => {
        return (
          <Input
            label={
              <div style={{ display: "flex", gap: "4px" }}>
                <Typography variant="caption.100" color="text_20">
                  복구 이메일
                </Typography>
                <Typography variant="caption.100" color="system_error">
                  *
                </Typography>
              </div>
            }
            placeholder="해당 이메일은 계정을 찾을 때 이용됩니다."
            value={field.value}
            onChange={(e) => onChange(e, field)}
            error={error}
            errorMessage={errorMessage}
          />
        );
      }}
    />
  );
};

export default Password;
