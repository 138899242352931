"use client";

import { useEffect } from "react";
import { Button } from "_components/Button";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import useLoginSignUp from "_hooks/use-login-sign-up";
import { verifyResetPasswordToken } from "_libs/fetcher/auth";
import colorToken from "_styles/colorToken";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import styled from "styled-components";

import Agree from "./SignUp/Agree";
import Complete from "./SignUp/Complete";
import FindEmail from "./FindEmail";
import LoginStep from "./Login";
import Password from "./Password";
import SignUp from "./SignUp";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const authStep = {
  login: LoginStep,
  sign: SignUp,
  complete: Complete,
  email: FindEmail,
  password: Password,
  agree: Agree,
};

const LoginSignUp = () => {
  const { currentTab, setTab, handlePasswordStep } = useLoginSignUp();
  const searchParams = useSearchParams();
  const getToken = searchParams.get("token");
  const { open } = useSnackBar();

  useEffect(() => {
    const checkEmailAndSetStep = async () => {
      if (!getToken) {
        setTab("login");
        return;
      }

      try {
        const result = await verifyResetPasswordToken(getToken);

        if (result) {
          const { email } = result;
          const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

          if (!emailRegex.test(email)) return setTab("login");

          return handlePasswordStep();
        } else {
          setTab("login");
          router.replace("/");
          open({
            variant: "error",
            text: `인증번호가 만료되었습니다. 새로운 인증번호를 요청해주세요.`,
          });
        }
      } catch (error) {
        console.error("이메일 확인 중 오류 발생:", error);

        router.replace("/");

        setTab("login"); // 오류 발생 시 기본값으로 'login' 설정
      }
    };

    checkEmailAndSetStep();
  }, [getToken]);

  const CurrentStepComponent = authStep[currentTab];
  const router = useRouter();

  const handleLoginPage = () => {
    router.push("/");
    setTab("login");
  };

  return (
    <>
      <S.HeaderContainer style={{ boxSizing: "border-box" }}>
        <S.Title href="/" onClick={() => handleLoginPage()}>
          <span />
        </S.Title>
        <Button variant="gray2" size="md" onClick={() => handleLoginPage()}>
          로그인
        </Button>
      </S.HeaderContainer>
      <S.Container>
        <CurrentStepComponent />
      </S.Container>
    </>
  );
};

export default LoginSignUp;

const S = {
  HeaderContainer: styled("div")(() => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "69px",
    padding: "0 20px",
  })),
  HeaderContent: styled("div")(() => ({
    flex: 1,
  })),
  Container: styled("div")<any>(() => ({
    width: "100%",
    height: "calc(100vh - 69px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: colorToken.background.default2,
  })),
  Title: styled(Link)(() => ({
    marginRight: "180px",
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    cursor: "default",
    textDecoration: "none",
    "@media (max-width: 1024px)": {
      justifyContent: "center",
    },

    span: {
      color: "var(--line_40)",
      fontSize: "32px",
      fontWeight: 900,
      cursor: "pointer",
      "&:before": {
        content: '"PlWida"',
        "@media (max-width: 1024px)": {
          content: '"P"',
        },
      },
    },
  })),
};
