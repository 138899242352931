import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "_components/Button";
import ReactMarkdownRenderer from "_components/Markdown/ReactMarkdownRenderer";
import Typography from "_components/Typography";
import styled from "styled-components";

export interface Message {
  id: number;
  type: "question" | "answer" | "notification";
  content: string;
}

const Content = ({ messages }: { messages: Message[] }) => {
  const { t } = useTranslation();
  const textareaRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
  const chatBodyRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  const handleChangeAccept = (messageId: number) => {
    // 변경 수락 로직 구현
    console.log(`변경 수락: 메시지 ID ${messageId}`);
    // 여기에 변경 수락 처리 로직을 추가하세요
  };

  const handleChangeReject = (messageId: number) => {
    // 변경 거부 로직 구현
    console.log(`변경 거부: 메시지 ID ${messageId}`);
    // 여기에 변경 거부 처리 로직을 추가하세요
  };

  useEffect(() => {
    textareaRefs.current.forEach((ref) => {
      if (ref) {
        ref.style.height = "auto";
        ref.style.height = ref.scrollHeight + "px";
      }
    });
    scrollToBottom();
  }, [messages]);

  return (
    <S.ChatbotBody ref={chatBodyRef}>
      <S.ScrollableContent>
        {messages.map((message, index) => (
          <S.MessageBox
            key={message.id}
            messageType={message.type}
            isFirst={index === messages.length - 1}
            isLast={index === 0}
          >
            {message.type === "notification" ? (
              <>
                <Typography variant="body.300" color="text_5">
                  <S.NotificationContent>
                    {message.content}
                  </S.NotificationContent>
                </Typography>
                <S.NotificationButtonContainer>
                  <Button
                    size="xs"
                    variant="default"
                    width="95px"
                    style={{
                      backgroundColor: "var(--black)",
                      color: "var(--white)",
                    }}
                    onClick={() => handleChangeAccept(message.id)}
                  >
                    {t("kuraClient.content.accept")}
                  </Button>
                  <Button
                    size="xs"
                    variant="default"
                    width="95px"
                    style={{
                      backgroundColor: "var(--Bgray_100)",
                      color: "var(--black)",
                    }}
                    onClick={() => handleChangeReject(message.id)}
                  >
                    {t("kuraClient.content.reject")}
                  </Button>
                </S.NotificationButtonContainer>
              </>
            ) : (
              <Typography variant="body.300" color="text_5">
                <S.MessageContent>
                  <ReactMarkdownRenderer content={message.content} />
                </S.MessageContent>
              </Typography>
            )}
          </S.MessageBox>
        ))}
      </S.ScrollableContent>
    </S.ChatbotBody>
  );
};

export default Content;

const S = {
  NotificationContent: styled("div")({
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  }),
  MessageContent: styled("div")({
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  }),
  //
  ScrollableContent: styled("div")({
    display: "flex",
    flexDirection: "column-reverse",
    minHeight: "min-content",
    padding: "0 16px",
    overflow: "visible",
  }),
  MessageBox: styled("div")<{
    messageType: "question" | "answer" | "notification";
    isFirst: boolean;
    isLast: boolean;
  }>(({ messageType, isFirst, isLast }) => ({
    margin: "6px 0",
    padding: "16px",
    borderRadius: "10px",
    maxWidth: "100%",
    boxShadow: "0px 3px 15px 0px rgba(117, 117, 117, 0.15)",
    backgroundColor: "var(--white)",
    ...(messageType === "question" && {
      backgroundColor: "#EAECF0",
      color: "var(--text_10)",
    }),
    ...(messageType === "answer" && {
      color: "black",
    }),
    ...(messageType === "notification" && {
      color: "black",
      display: "flex",
      flexDirection: "column",
    }),
    ...(isFirst && {
      marginTop: "10px",
    }),
    ...(isLast && {
      marginBottom: "15px",
    }),
  })),
  ChatbotBody: styled("div")({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    margin: "0 -16px",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  }),
  NotificationButtonContainer: styled("div")({
    paddingTop: "20px",
    display: "flex",
    columnGap: "12px",
  }),
  NotificationTextarea: styled("textarea")({
    width: "100%",
    marginBottom: "10px",
    border: "none",
    borderRadius: "4px",
    resize: "none",
    overflow: "hidden",
    minHeight: "40px",
    backgroundColor: "transparent",
    "&:focus": {
      outline: "none",
    },
    font: "inherit",
    color: "inherit",
  }),
};
