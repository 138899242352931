import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import LOGO from "_assets/logo/PlWida.svg";
import SVG from "_components/SVG/Svg";
import Typography from "_components/Typography";
import useLoginSignUp from "_hooks/use-login-sign-up";

import { emailStepConfig } from "./EmailStepConfig";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FindEmail = () => {
  const sliderRef = useRef<Slider>(null);

  const { setTab } = useLoginSignUp();

  const { watch, setValue, handleSubmit, control } = useForm({
    // defaultValues: initData,
  });

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: true, // false로 바꿔야함
    fade: false,
  };

  return (
    <form>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            width: "480px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            background: "white",
            padding: "40px",
            boxSizing: "border-box",
            borderRadius: "12px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <SVG src={LOGO} fill="fill" width={77} height={12} />
            <Typography variant="title.200_sb" color="text_5">
              이메일 찾기
            </Typography>
          </div>
          <Slider ref={sliderRef} {...settings}>
            {emailStepConfig.map((item: any, index) => (
              <div key={index}>
                {React.cloneElement(item.page, {
                  sliderRef,
                  setTab,
                  watch,
                  handleSubmit,
                  setValue,
                  control,
                })}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </form>
  );
};

export default FindEmail;
