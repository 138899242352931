export default {
  message: {
    wrongName:
      " * 입력하신 버킷 이름이 잘못되었습니다. 다시 확인 후 정확한 이름을 입력해 주세요.",
    unlink:
      " * 버킷 연동을 해제하면, 파일이 영구적으로 삭제되며 다시 복구할 수없습니다.",
  },
  title: "해당 버킷을 연동 해제하시겠습니까?",
  placeholder: "삭제를 위해 버킷 이름을 다시 입력해 주세요.",
} as const;
