import { ChangeEvent, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";

import { IFormProps } from ".";

const CompanyAdress = (props: IFormProps) => {
  const { control } = props;

  const [detailedAddress, setDetailedAddress] = useState("");

  const onChange = (e: ChangeEvent<HTMLInputElement>, field: any) => {
    const value = e.target.value;

    field.onChange(value);
    if (field.value) return;
  };

  const onClickPopup = () => {
    const url = `/popup`;
    const popup = window.open(
      url,
      "_blank",
      "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=510, height=510",
    );

    if (popup) {
      popup.document.write(`
        <html>
          <head>
            <title>주소 검색</title>
            <script src="//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"></script>
          </head>
          <body>
            <div id="postcode"></div>
            <script>
              new daum.Postcode({
                oncomplete: function(data) {
                  window.opener.postMessage(data, "*");
                  window.close();
              }
              }).embed(document.getElementById('postcode'));
            </script>
          </body>
        </html>
      `);
    }
  };

  const handleDetailedAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDetailedAddress(value);
  };

  return (
    <Controller
      name="companyAddress"
      control={control}
      render={({ field }) => {
        useEffect(() => {
          const handleMessage = (event: MessageEvent) => {
            if (event.origin !== window.location.origin) return;

            const data = event.data;

            if (data && data.address) {
              field.onChange(data.address);
            }
          };

          window.addEventListener("message", handleMessage);

          return () => {
            window.removeEventListener("message", handleMessage);
          };
        }, [field]);

        return (
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Input
              label={
                <Typography variant="caption.100" color="text_20">
                  회사 주소
                </Typography>
              }
              placeholder="회사 주소 찾기"
              value={field.value}
              onChange={(e) => onChange(e, field)}
              onClick={onClickPopup}
              tabIndex={-1}
              readOnly
            />
            <Input
              placeholder="상세 주소"
              value={detailedAddress}
              onChange={(e) => handleDetailedAddressChange(e)}
              tabIndex={-1}
            />
          </div>
        );
      }}
    />
  );
};

export default CompanyAdress;
