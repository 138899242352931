export default {
  solution: {
    title: "Kura를 통해",
    subtitle: "최적의 맞춤 솔루션을 경험하세요!",
    upload: "IR자료 혹은 기업소개서 업로드",
    button: "솔루션 받기",
  },
  resourceStatus: {
    title: "리소스 상태",
    total: "전체",
    stopped: "정지",
    running: "실행",
    other: "기타",
    state: "상태",
    serverName: "서버 이름",
    vCore: "vCore",
    memory: "메모리",
    disk: "디스크",
  },
  issuesByRisk: {
    title: "위험별 이슈",
    allRisk: "전체 위험요소",
    critical: "위험",
    high: "높음",
    medium: "중간",
    low: "낮음",
    normal: "정상",
  },
  billing: {
    title: "청구서",
    monthToDateCost: "이번 달 누적 비용",
    estimatedCost: "예상 비용",
    previousDayCost: "전일 비용",
    previousMonthCost: "전월 비용",
    today: "오늘",
  },
  issueLogs: {
    title: "이슈 로그",
    createdAlarm: "경보 생성",
    noIssueLogs: "현재 표시할 이슈 로그가 없습니다.",
    setAlarm: "경보 설정",
  },
  notice: {
    title: "공지사항",
    viewAll: "전체보기",
  },
} as const;
