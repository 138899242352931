import header from "./components/header";
import kuraClient from "./components/kuraClient";
import modal from "./components/modal";
import phoneNumberInput from "./components/phoneNumberInput";
import snb from "./components/snb";
import AI from "./page/AI";
import billing from "./page/billing";
import calendar from "./page/calendar";
import consult from "./page/consult";
import dashboard from "./page/dashboard";
import dataLabeling from "./page/data-labeling";
import externalConnection from "./page/external-connection";
import management from "./page/management";
import monitoring from "./page/monitoring";
import smartMatching from "./page/smart-matching";
import storage from "./page/storage";

export default {
  aws: "AWS",
  azure: "Azure",
  gcp: "GCP",
  oracle: "Oracle",
  description: "설명",
  // components
  header: header,
  snb: snb,
  modal: modal,
  kuraClient: kuraClient,
  phoneNumberInput: phoneNumberInput,
  // pages
  dashboard: dashboard,
  consult: consult,
  storage: storage,
  externalConnection: externalConnection,
  monitoring: monitoring,
  dataLabeling: dataLabeling,
  AI: AI,
  smartMatching: smartMatching,
  management: management,
  calendar: calendar,
  billing: billing,
} as const;
