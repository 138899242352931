export default {
  progressSteps: {
    first: "목표 설정 하기",
    second: "학습 모델 선택하기",
    third: "평가 지표 추천 받기",
    fourth: "평가 지표 분석 받기",
    fifth: "AI 모델 설계 완료!",
    title: "모델 설계 진행도",
    buttonLabel: "AI 모델 학습 시키러 가기",
  },
  kuraChat: {
    buttonLabel: "다른 예시를 추천해줘",
    title: "모델 설계하기 2단계",
    description: {
      first: ` 제가 “사용자 행동 데이터를 기반으로 한 추천 알고리즘을 개선하여
                클릭률(CTR)을 현재 5%에서 10%로 향상시킨다”의 목표에 맞는 모델을
                자동으로 추천해 드릴게요.`,
      second: "선택한 모델 : 로지스틱 회귀 (Logistic Regression)",
      third: `이진 분류 문제에 적합: 방문자의 체류시간이 100초 이상인지
                아닌지를 예측하는 이진 분류 문제에요. 
                해석 용이성: 로지스틱 회귀는 각 특징(feature)의 회귀 계수를 통해 해당 특징이 결과에
                미치는 영향을 해석할 수 있어 비즈니스 인사이트 도출에 유용해요.
                데이터 크기 적합성: 데이터셋이 작고 단순하기 때문에 복잡한
                모델보다 로지스틱 회귀가 적합해요.`,
    },
  },
  chatInput: {
    placeholder: "궁금한게 있으면 얼마든지 물어보세요.",
  },
  leftLabel: "모델 설계하기",
  table: {
    visitDate: "방문일자",
    visitorId: "방문자ID",
    pageUrl: "페이지URL",
    inboundPath: "유입경로",
    stayTime: "체류시간",
    device: "디바이스",
    browser: "브라우저",
  },
} as const;
