export default {
  solution: {
    title: "Discover Perfectly ",
    subtitle: "Tailored Solutions with Kura!",
    upload: "Upload IR & Company Profile",
    button: "Get Solution",
  },
  resourceStatus: {
    title: "Resource Status",
    total: "Total",
    stopped: "Stopped",
    running: "Running",
    other: "Other",
    state: "Status",
    serverName: "Server Name",
    vCore: "vCore",
    memory: "Memory",
    disk: "Disk",
  },
  issuesByRisk: {
    title: "Issues by Risk",
    allRisk: "All Risk",
    critical: "Critical",
    high: "High",
    medium: "Medium",
    low: "Low",
    normal: "Normal",
  },
  billing: {
    title: "Billing",
    monthToDateCost: "Month-to-date cost",
    estimatedCost: "Total forecasted cost for current month",
    previousDayCost: "Last day's total cost",
    previousMonthCost: "Last month's total cost",
    today: "Today",
  },
  issueLogs: {
    title: "Issue Logs",
    createdAlarm: "Create alarm",
    noIssueLogs: "No issue logs to display currently.",
    setAlarm: "Set alarm",
  },
  notice: {
    title: "Notice",
    viewAll: "View All",
  },
} as const;
