export default {
  organization: {
    title: "조직",
    add: "조직 추가",
    all: "전체",
    searchPlaceholder: "검색어를 입력해주세요.",
    organizationName: "조직명",
    organizationLeader: "조직장",
    members: "인원",
  },
  members: {
    title: "구성원",
    name: "이름",
    email: "이메일",
    jobTitle: "직무",
    authority: "권한",
    modify: "구성원 수정",
  },
  modifyMemberModal: {
    title: "구성원 정보",
    name: "이름",
    email: "이메일",
    organization: "조직",
    jobTitle: "직무",
    authority: "권한",
    changeLeader: "조직장 변경",
    save: "저장",
    cancel: "취소",
    message: {
      success: "의 구성원 정보가 변경되었습니다.",
    },
  },
} as const;
