import { ChangeEvent, useState } from "react";
import { Controller } from "react-hook-form";
import Input from "_components/Input/Input";
import { Tooltip } from "_components/Tooltip";
import Typography from "_components/Typography";
import { icons } from "_styles/shared/icons";
import {
  hasLowercase,
  hasNumber,
  hasSpecialChar,
  hasUppercase,
  isLengthValid,
} from "_utils/password";
import Image from "next/image";

import { IFormProps } from ".";

const Password = (props: IFormProps) => {
  const { control } = props;

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [firstPassword, setFirstPassword] = useState("");

  const [isPasswordFocus, setIsPasswordFocus] = useState(false);
  const [isPasswordCheck, setIsPasswordCheck] = useState({
    isLengthValid: false,
    hasSpecialChar: false,
    hasNumber: false,
    hasUppercase: false,
    hasLowercase: false,
  });

  const handlePasswordOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: any,
  ) => {
    const value = e.target.value;

    field.onChange(value);
    setFirstPassword(value);

    setIsPasswordCheck({
      isLengthValid: isLengthValid(value),
      hasSpecialChar: hasSpecialChar(value),
      hasNumber: hasNumber(value),
      hasUppercase: hasUppercase(value),
      hasLowercase: hasLowercase(value),
    });
  };

  const handleCheckPassword = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCheckPassword(value);

    // 비밀번호 일치 여부 확인
    if (value && value !== firstPassword) {
      setError(true);
      setErrorMessage("비밀번호가 일치하지 않습니다.");
    } else {
      setError(false);
      setErrorMessage("");
    }
  };

  return (
    <Controller
      name="password"
      control={control}
      render={({ field }) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Tooltip
              open={isPasswordFocus}
              content={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Typography variant="caption.100" color={"white"}>
                    비밀번호 필수 조건
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                    }}
                  >
                    <CheckTooltip
                      contents={"8자 이상, 16자 이하"}
                      check={isPasswordCheck.isLengthValid}
                    />
                    <CheckTooltip
                      contents={"대문자 조합 사용"}
                      check={isPasswordCheck.hasUppercase}
                    />
                    <CheckTooltip
                      contents={"소문자 조합 사용"}
                      check={isPasswordCheck.hasLowercase}
                    />
                    <CheckTooltip
                      contents={"숫자 조합 사용"}
                      check={isPasswordCheck.hasNumber}
                    />
                    <CheckTooltip
                      contents={"특수문자 조합 사용"}
                      check={isPasswordCheck.hasSpecialChar}
                    />
                  </div>
                </div>
              }
              sideOffset={10}
              side="right"
            >
              <Input
                onFocus={() => {
                  setIsPasswordFocus(true);
                }}
                type="password"
                onBlur={() => {
                  setIsPasswordFocus(false);
                }}
                label={
                  <div style={{ display: "flex", gap: "4px" }}>
                    <Typography variant="caption.100" color="text_20">
                      비밀번호
                    </Typography>
                    <Typography variant="caption.100" color="system_error">
                      *
                    </Typography>
                  </div>
                }
                placeholder="8~16자리 / 대소문자, 숫자, 특수문자 조합"
                value={field.value}
                onChange={(e: any) => handlePasswordOnChange(e, field)}
                // error={error}
                // errorMessage={errorMessage}
              />
            </Tooltip>

            <Input
              type="password"
              placeholder="비밀번호를 다시 한번 입력해 주세요."
              value={checkPassword}
              onChange={handleCheckPassword}
              error={error}
              errorMessage={errorMessage}
            />
          </div>
        );
      }}
    />
  );
};

export default Password;

const CheckTooltip = (props: any) => {
  const { check, contents } = props;

  return (
    <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
      <Image
        src={check ? icons.check.main_20 : icons.check.white_16}
        width={12}
        height={12}
        alt=""
      />
      <Typography variant="caption.100" color={check ? "white" : "text_30"}>
        {contents}
      </Typography>
    </div>
  );
};
