import Typography from "_components/Typography";

const SendEmailComplete = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography variant="body.100" color="text_5">
        메일이 전송되었습니다. 이메일을 확인해 주세요.
      </Typography>
      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Typography variant="body.200_sb" color="text_15">
          유의사항
        </Typography>
        <Typography variant="body.200" color="text_15">
          요청 후 메일이 도착하지 않았다면 스팸함을 확인해주세요.
        </Typography>
      </div>
    </div>
  );
};

export default SendEmailComplete;
