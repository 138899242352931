import highFive from "./high-five";
import matchingStatus from "./matching-status";
import plwidaMatching from "./plwida-matching";

export default {
  matchingStatus,
  plwidaMatching,
  highFive,
  userMatchStatus: {
    receivedProposal: "받은 제안",
    limitStatus: "제안 현황",
    bookmark: "북마크",
    viewCount: "내 기업 정보 열람 수",
    myMatchingInfo: "내 매칭 정보",
    myMatchingInfoDescription: "님의 매칭 현황입니다.",
  },
  plwidaMatch: {
    title: "PlWiDa 매칭",
    viewAll: "전체보기",
    menu: {
      quickFindCompany: {
        title: "빠르게 기업 찾고 컨택해요",
        sub: "🖐🏻 하이파이브! / 짝꿍 기업 매칭",
      },
      increaseCompanyValue: {
        title: "기업의 가치를 올리는",
        sub: "✨내가 원하던 / 펀드 매칭",
      },
      searchDifficultTask: {
        title: "힘든 과제 서치는 이제 NO!",
        sub: "👀 찾고 있던 / 과제 추천 받기",
      },
      customRecommendService: {
        title: "고객 맞춤 추천 서비스",
        sub: "🗃️ 기업 퍼스널 / 데이터셋 상품",
      },
    },
  },
  news: {
    title: "기업 트렌드 NEWS",
  },
  highFiveCompany: {
    title: "하이파이브 기업",
    viewAll: "전체보기",
    companyInfo: "기업 정보",
  },
} as const;
