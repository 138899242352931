import { create } from "zustand";

export type TabType =
  | "login"
  | "sign"
  | "setting"
  | "email"
  | "complete"
  | "password";

export interface LoginSignUpTabState {
  currentTab: TabType;
  loginStep: number;
  signUpStep: number;
  settingStep: number;

  setTab: (tab: TabType) => void;
  setLoginStep: (step: number) => void;
  setSignUpStep: (step: number) => void;
  setSettingStep: (step: number) => void;

  resetSteps: () => void;
}

const useLoginSignUpTabStore = create<LoginSignUpTabState>((set) => ({
  currentTab: "login",
  loginStep: 0,
  signUpStep: 0,
  settingStep: 0,

  setTab: (tab) => set({ currentTab: tab }), // 'login' 또는 'signup'으로 제한

  setLoginStep: (step) =>
    set((state) => ({
      loginStep: Math.min(Math.max(0, step), 2), // 0에서 2 사이로 제한
    })),

  setSignUpStep: (step) =>
    set((state) => ({
      signUpStep: Math.min(Math.max(0, step), 3), // 0에서 5 사이로 제한
    })),

  setSettingStep: (step) =>
    set((state) => ({
      settingStep: Math.min(Math.max(0, step), 1), // 0에서 1 사이로 제한
    })),

  resetSteps: () => set({ loginStep: 0, signUpStep: 0, settingStep: 0 }),
}));

export default useLoginSignUpTabStore;
