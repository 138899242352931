export default {
  header: {
    title: "Kura Assist",
    description: "Kura provides you with today's report.",
  },
  content: {
    accept: "Changed",
    reject: "Not Changed",
    notification1: `How about switching from 'AWS' to 'Oracle' to reduce cloud operations and analysis resources?
  
By making the change, you could reduce operational costs by 24% compared to last month and improve security by 40%, enhancing stability.

The quantitative cost-saving effect is approximately 3.65 million KRW.

Would you like to make the switch now?`,

    notification2: `Text data has increased by 25.5% since last month. 📈
  
Using AWS storage could reduce operational costs by up to 20%.

    Would you like to make the change now?`,
  },
  input: {
    placeholder: "What service do you need?",
  },
} as const;
