import detail from "./detail";

export default {
  detail,
  title: "Create AI Model",
  kuraAssistCard: {
    title: "Kura Assist",
    description: {
      first: "Kura Supports You From Building AI Models",
      second: "To Crafting Evaluation Metrics.",
    },
    button: "Create my model",
  },
  aiModelStats: {
    title: "AI Training Model",
    content: {
      newPartner: "New Partner",
      kuraAssist: "Kura Assist",
      getToKnowKura: "Get to know Kura",
      usingInPIWIDa: "Used in PIWIDA",
      usage: "100% Usage",
      aiModelBuilding: "AI Model Building",
      process: "Process",
    },
  },
  aiModelUsageSamples: {
    title: "Other Company Model Success Stories",
    description: "See the model success stories shared by other companies.",
  },
  taskList: {
    title: "My Model Status",
    description: "Recent AI model. Double-click to continue working.",
    table: {
      name: "Name",
      recentWorkDate: "Recent Work Date",
      status: "Status",
      evaluationProgress: "Evaluation Progress",
    },
  },
} as const;
