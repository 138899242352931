export default {
  title: "High Five",
  tabList: {
    company: "Company",
    fund: "Fund",
    task: "Task",
  },
  highFiveList: {
    searchPlaceholder: "Please enter a search term.",
    addFunction: "Add Function",
    companyInfo: "Company Info",
  },
} as const;
