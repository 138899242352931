export default {
  default: {
    title: "Consulting Schedule Information",
    description: "You can modify the schedule before it is confirmed.",
    button: {
      cancel: "Modify Schedule",
      confirm: "Confirm",
    },
  },
  success: {
    title: "Consulting Schedule Information",
    description: "Click confirm to request the schedule.",
    button: {
      cancel: "Cancel",
      confirm: "Confirm",
    },
  },
  name: "Contact Person",
  consultingDate: "Consulting Date",
  consultingTime: "Consulting Time",
  meetingMethod: "Meeting Method",
  state: "Status",
  online: "Online Zoom Meeting",
  offline: "Offline In-person Meeting",
  waiting: "The contact person is reviewing the schedule.",
  confirmed: "Schedule Confirmed",
} as const;
