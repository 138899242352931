export default {
  dataLabeling: {
    title: "Select the file to label.",
  },
  message: {
    uploadSuccess: "File uploaded successfully!",
  },
  ai: {
    title: "Select the file for training.",
  },
  upload: "Upload",
} as const;
