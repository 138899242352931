export default {
  title: "PlWiDa Matching",
  tabList: {
    company: "Company",
    fund: "Fund",
    task: "Task",
    data: "Data",
  },
  company: {
    title: "🖐️ Explore and propose to the companies you’re interested in.",
    propose: "Propose",
  },
  fund: {
    title: "🔍 Discover funds and submit your proposals first.",
    apply: "Apply",
  },
  task: {
    title: "👀 Apply for tasks tailored to your interests.",
    searchPlaceholder: "Enter the task name.",
    checkBoxList: {
      all: "All",
      pending: "Pending",
      upcoming: "Upcoming",
    },
    registrationDate: "Registration Date",
    apply: "Apply",
  },
  data: {
    title:
      "👍 Get personalized recommendations for the perfect dataset products.",
    viewProduct: "View Product",
  },
} as const;
