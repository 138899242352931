export default {
  monthToDateCost: "Month-to-date Cost",
  estimatedCost: "Estimated Cost",
  previousDayCost: "Previous Day Cost",
  previousMonthCost: "Previous Month Cost",
  invoiceDownload: "Invoice Download",
  forecastCost: "6 months or more of data is required.",
  monthlyCost: {
    title: "Monthly Cost",
  },
  dailyCost: {
    title: "Daily Cost",
  },
  productCost: {
    title: "Product Cost",
  },
  regionCost: {
    title: "Region Cost",
    region: "Region",
    previousMonth: "Previous Month",
    thisMonth: "This Month",
    monthToMonth: "MoM Usage Fee Difference",
  },
} as const;
