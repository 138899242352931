export default {
  add: "일정 추가",
  today: "오늘",
  month: "달 단위",
  week: "주 단위",
  day: "일",
  sunday: "일",
  monday: "월",
  tuesday: "화",
  wednesday: "수",
  thursday: "목",
  friday: "금",
  saturday: "토",
  myCalendar: {
    title: "내 일정",
    businessTrip: "출장",
    meeting: "회의",
    personalSchedule: "개인 일정",
    consulting: "컨설팅",
  },

  project: "프로젝트",
} as const;
