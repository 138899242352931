export default {
  header: {
    title: "Kura Assist",
    description: "Kura가 오늘의 리포트를 알려드립니다",
  },
  content: {
    accept: "변경함",
    reject: "변경안함",
    notification1: `클라우드 운영과 분석 리소스를 절감하려면 'AWS'에서 'Oracle'로 변경하는 건 어떠세요?

변경 시, 지난달 대비 운영비를 24% 절감하고, 보안이 강화되어 안정성은 40% 향상돼요! 

정량적인 비용 절감 효과는 약 365만 원입니다

지금 변경해 보시겠어요?`,

    notification2: `지난달부터 텍스트 데이터가 25.5% 증가했습니다. 📈

AWS 스토리지를 이용하시면 운영 비용을 최대 20%까지 절감할 수 있습니다. 

지금 변경해 보시겠어요?`,
  },
  input: {
    placeholder: "어떤 서비스가 필요한가요?",
  },
} as const;
