export default {
  title: "Consulting for Data-Driven Success",
  content:
    "Accelerate your business growth with customized analysis and insights.",

  checkSchedule: "Check Schedule",
  startConsulting: "Start Consulting",
  alreadyConsulting: "Already a consulting request exists.",
  successConsulting: "Consulting request completed.",
  one: {
    title: "Please confirm your contact information",
    content: "You’ll receive Coffee Chat notifications at this contact.",
    companyName: {
      label: "Company Name",
      placeholder: "Enter your company or business name.",
    },
    chiefName: {
      label: "Representative Name",
      placeholder: "Enter the representative's name.",
    },
    phoneNumber: {
      label: "Phone Number",
      placeholder: "Enter your phone number.",
    },
    email: {
      label: "Email",
      placeholder: "Enter your email address.",
      error: "Invalid email format.",
    },
  },
  two: {
    title: "Interested in consulting?",
    content:
      "Provide the necessary information, and we’ll offer optimized consulting services.",
    field: "Field",
    fieldList: {
      project: "Project",
      fund: "Fund",
      data: "Data",
      cloud: "Cloud",
      etc: "Others",
    },
    request: {
      label: "Consulting Request Details",
      placeholder: "Enter your consulting request details.",
    },
  },
  three: {
    title: "Choose your consultant",
    content: "Your consultant will contact you at the selected time.",
    consultant: "Consultant",
    monday: "Mon",
    tuesday: "Tue",
    wednesday: "Wed",
    thursday: "Thu",
    friday: "Fri",
    saturday: "Sat",
    sunday: "Sun",
    meetingMethod: "Select your preferred meeting method.",
    meetingMethodList: {
      zoom: {
        label: "Zoom Video Meeting",
        description:
          "A Zoom link will be sent to your email upon confirmation.",
      },
      offline: {
        label: "Offline In-person Meeting",
        description:
          "Meeting details will be sent to your email upon confirmation.",
      },
    },
  },
  next: "Next",
  submit: "Submit",
  prev: "Prev",
} as const;
