export default {
  title: "PlWiDa 매칭",
  tabList: {
    company: "기업",
    fund: "펀드",
    task: "과제",
    data: "데이터",
  },
  company: {
    title: "🖐️ 제안하고 싶은 기업을 찾아보세요.",
    propose: "제안하기",
  },
  fund: {
    title: "🔍 펀드를 탐색하고 먼저 제안 해보세요.",
    apply: "신청하기",
  },
  task: {
    title: "👀 나에게 맞는 과제를 신청할 수 있어요.",
    searchPlaceholder: "과제명을 입력해 보세요.",
    checkBoxList: {
      all: "전체",
      pending: "접수중",
      upcoming: "접수예정",
    },
    registrationDate: "접수일",
    apply: "신청하기",
  },
  data: {
    title: "👍 회원님께 딱 맞는 데이터셋 상품을 추천 해드릴게요.",
    viewProduct: "상품 보러가기",
  },
} as const;
