import { useEffect } from "react";
import { usePathname, useRouter } from "next/navigation";

export const useLayoutRedirect = (userData: any) => {
  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    if (
      userData &&
      userData.connect === false &&
      !pathname.includes("/external-connection")
    ) {
      router.replace("/external-connection");
    }
  }, [userData, pathname, router]);
};
