export default {
  leftLabel: "AI Planning",
  kuraChat: {
    title: "Create a Mind Map",
    description: {
      first: `Please describe the business problem or task you want to solve 
              using the website traffic log files. The more specific and measurable 
              your goal, the better! For example, "Increasing the click-through 
              rate of the product recommendation system by 15%" is a great goal.`,
      second: `Your goal has been set! I’ll create a mind map tailored to your 
              defined objective. Once you click confirm, my response will disappear. 
              If you have any questions, feel free to ask me anytime through chat!`,
    },
    buttonLabel: "Confirm",
  },
  mindMap: {
    snackbar: "Creating a mind map for the project proposal",
  },
  searchPlaceholder: "Feel free to ask anything you’re curious about.",
} as const;
