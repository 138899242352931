/* eslint-disable turbo/no-undeclared-env-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useEventSource from "_hooks/useEventSource";

import KuraClient from ".";

interface Message {
  id: number;
  type: "question" | "answer" | "notification";
  content: string;
}

const KuraApp: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const { connect, disconnect } = useEventSource();

  const mockNotification: any = [
    {
      id: 2,
      type: "notification",
      content: t("kuraClient.content.notification1"),
    },
    {
      id: 1,
      type: "notification",
      content: t("kuraClient.content.notification2"),
    },
  ];

  const toggleChatbot = () => setIsOpen(!isOpen);

  useEffect(() => {
    // 초기 메시지 설정 (AI 뷰가 열릴 때만 실행)
    if (messages.length === 0) {
      setMessages(mockNotification);
    }
    // 언어 변경 시 메시지 변경
    // setMessages(mockNotification);

    // AI 뷰가 닫힐 때 연결 해제
    if (!isOpen) {
      disconnect();
    }
  }, [isOpen, disconnect, messages.length, i18n]);

  const handleSendMessage = useCallback(
    (message: string) => {
      const newMessage: Message = {
        id: Date.now(),
        type: "question",
        content: message,
      };

      setMessages((prevMessages) => [newMessage, ...prevMessages]);

      let fullResponse = "";

      // gpt-4, gpt-4o, gpt-3.5-turbo, gpt-4o-mini
      connect({
        url: `${process.env.NEXT_PUBLIC_API_URL}ai/chat-gpt?message=${encodeURIComponent(message)}&model=gpt-4o&type=gpt`,
        onMessage: (data) => {
          fullResponse += data;
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const answerIndex = updatedMessages.findIndex(
              (msg) => msg.type === "answer" && msg.id === newMessage.id + 1,
            );

            if (answerIndex !== -1) {
              updatedMessages[answerIndex] = {
                ...updatedMessages[answerIndex],
                content: fullResponse,
              };
            } else {
              updatedMessages.unshift({
                id: newMessage.id + 1,
                type: "answer",
                content: fullResponse,
              });
            }

            return updatedMessages;
          });
        },
        onError: (error) => {
          console.error("EventSource failed:", error);
        },
      });
    },
    [connect],
  );

  return (
    <>
      {isOpen && (
        <KuraClient>
          <KuraClient.Header />
          <KuraClient.Content messages={messages} />
          <KuraClient.Input onSendMessage={handleSendMessage} />
        </KuraClient>
      )}
      <KuraClient.Toggle onClick={toggleChatbot} />
    </>
  );
};

export default KuraApp;
