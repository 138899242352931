export default {
  title: "매칭 상태",
  receivedProposal: "받은 제안",
  limitStatus: "제안 현황",
  bookmark: "북마크",
  matchList: {
    searchPlaceholder: "기업명을 입력해 보세요.",
    type: "유형",
    companyName: "기업명",
    status: "상태",
    proposalDate: "제안일",
    viewProposal: "제안서 열람",
    addFunction: "추가 기능",
    company: "기업",
    fund: "펀드",
    task: "과제",
    data: "데이터",
  },
  bookmarkCard: {
    company: "기업",
    fund: "펀드",
    task: "과제",
    data: "데이터",
    propose: "제안하기",
  },
} as const;
