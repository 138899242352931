export default {
  bucketSelect: {
    title: "Select Bucket",
    description: "Select a bucket from the bucket list to link to the storage.",
    button: "Check Bucket",
    next: "Next",
  },
  corsSetting: {
    title: "CORS Setting",
    description: "Enter the bucket you want to link to the storage.",
    copy: "Copy",
    confirm: "Confirm",
  },
  bucketInfo: {
    title: "Bucket information",
    name: "Name",
    namePlaceholder: "Enter the name to be displayed on Plwida.",
    description: "Description (optional)",
    descriptionPlaceholder: "Enter the description.",
  },
  connect: "Connect",
  loading: "Loading bucket list.",
  noBucket: "No bucket available for connection.",
} as const;
