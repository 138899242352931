import { Button } from "_components/Button";
import Typography from "_components/Typography";

const Complete = (props: any) => {
  const { setTab } = props;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography variant="body.100" color="text_5">
        본인 인증이 완료되었습니다.
        <br />
        회원님께서 가입하신 이메일 계정 입니다.
      </Typography>
      <div
        style={{
          display: "flex",
          background: "var(--background_default2)",
          padding: "16px",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
        }}
      >
        <Typography variant="body.200_sb" color="text_15">
          email
        </Typography>
      </div>

      <div style={{ alignSelf: "flex-end", marginTop: "42px" }}>
        <Button variant="default" size="md" onClick={() => setTab("login")}>
          확인
        </Button>
      </div>
    </div>
  );
};

export default Complete;
