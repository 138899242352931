export default {
  default: {
    title: "컨설팅 일정 정보",
    description: "확정 전엔 일정을 변경할 수 있어요.",
    button: {
      cancel: "일정 변경하기",
      confirm: "확인",
    },
  },
  success: {
    title: "컨설팅 일정 정보",
    description: "확인 버튼을 누르면 일정이 요청 돼요.",
    button: {
      cancel: "취소",
      confirm: "확인",
    },
  },
  name: "담당자",
  consultingDate: "컨설팅 날짜",
  consultingTime: "컨설팅 시간",
  meetingMethod: "미팅 방법",
  state: "상태",
  online: "온라인 Zoom 미팅",
  offline: "오프라인 대면 미팅",
  waiting: "담당자가 일정을 확인 중 이에요",
  confirmed: "일정 확정",
} as const;
